import Swiper from "swiper";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/swiper.css";
import "swiper/modules/navigation.css";
import "swiper/modules/pagination.css";
Swiper.use([Autoplay, Pagination, Navigation]);

const language =
  window.location.pathname.split("/").filter(Boolean).pop() ?? "fr";

/*
 * JOB OFFERS
 * Record<'htmlTitle' | 'subText' | 'link', string>
 */
// Display job offers
const jobsFR = [];

const jobsEN = [];

const offerHTML = document.createElement("article");
offerHTML.classList.add(
  "w-[282px]",
  "h-[372px]",
  "bg-[#FAFAFA]",
  "text-left",
  "p-7",
  "box-border",
  "flex",
  "flex-col",
  "justify-between",
  "shadow-yellow",
  "max-[1280px]:w-full",
  "max-[1280px]:max-w-[600px]",
  "max-[1280px]:mx-auto",
  "max-[1280px]:h-[240px]"
);

const offersContainer =
  document.getElementById("offers_container_fr") ||
  document.getElementById("offers_container_en");

offersContainer.classList.add("flex", "flex-row", "gap-12", "flex-wrap");

function displayOffers(offers) {
  let rowsLength = Math.ceil(offers.length / 4);
  const offerButton = language === "en" ? "See offer" : "Voir l'offre";

  for (let i = 0; i < rowsLength; i++) {
    for (let j = 0; j < 4; j++) {
      const index = i * 4 + j;
      if (index < offers.length) {
        const offer = offerHTML.cloneNode();
        const defaultSubText =
          language === "en" ? "Permanent position - F/M" : "CDI - F/H";
        const subText = offers[index].subText || defaultSubText;
        offer.innerHTML = `
        <div>
          <h3 class="leading-none text-[28px]" style="${
            offers[index].style || ""
          }">
            ${offers[index].htmlTitle}
          </h3>
          <p class="text-gray-400 leading-none">${subText}</p>
        </div>
        <a class="bg-black text-white italic uppercase black text-[24px] block text-center p-4" href="${
          offers[index].link
        }" target="_blank" rel="noopener noreferrer">${offerButton}</a>
      `;
        offersContainer.appendChild(offer);
      }
    }
  }

  // Add "Submit your application", "Voir plus" and empty block to last row
  // "Submit your application"
  const submitApplicationHTML = offerHTML.cloneNode();
  const submitText =
    language === "en"
      ? "Would you like to submit your <span class='yellow'>application?</span>"
      : "Tu veux nous proposer ta <span class='yellow'>candidature ?</span>";
  const submitButton = language === "en" ? "Click here!" : "Par ici !";
  submitApplicationHTML.innerHTML = `
  <div>
    <h3 class="leading-none">
      ${submitText}
    </h3>
  </div>
  <a class="bg-black text-white italic uppercase black text-[24px] block text-center p-4" href="https://www.welcometothejungle.com/fr/companies/koala-interactive/jobs/candidatures-spontanees" target="_blank" rel="noopener noreferrer">${submitButton}</a>
`;
  offersContainer.appendChild(submitApplicationHTML);

  // "Voir plus"
  const seeMoreHTML = offerHTML.cloneNode();
  seeMoreHTML.classList.remove(
    "bg-[#FAFAFA]",
    "shadow-yellow",
    "justify-between"
  );
  seeMoreHTML.classList.add("justify-center", "text-center");
  seeMoreHTML.innerHTML = `
  <div>
    <a href="https://www.welcometothejungle.com/fr/companies/koala-interactive" target="_blank" rel="noopener noreferrer" class="inline-block uppercase black link-black text-black">${
      language === "en" ? "See more" : "Voir plus"
    }</a>
  </div>
`;

  offersContainer.appendChild(seeMoreHTML);
}

displayOffers(language === "fr" ? jobsFR : jobsEN);

/*
 * SWIPER SLIDER
 */
// init Swiper
const swiper = new Swiper(".swiper", {
  autoplay: {
    delay: 500000,
    disableOnInteraction: false,
  },
  loop: true,
  speed: 400,
  spaceBetween: 100,

  // If we need pagination
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
    renderBullet: (_index, className) => {
      return '<span class="' + className + '"></span>';
    },
  },

  // Navigation arrows
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

const toggleMenuButton = document.querySelector(".toggle_menu");
const menuContainer = document.querySelector(".app__menu__mobile");

toggleMenuButton.addEventListener("click", () => {
  menuContainer.classList.toggle("opened");
});

/*
 * LANGUAGE SELECTION
 */
let showLanguageModal = false;
const languageButton = document.getElementById("language-button");
const languageModal = document.getElementById("language-modal");
const languageButtonArrow = document.getElementById("language-button-arrow");
languageButton.addEventListener("click", () => {
  showLanguageModal = !showLanguageModal;
  languageModal.classList.toggle("hidden");

  languageButtonArrow.style.transform = showLanguageModal
    ? "scaleY(-1)"
    : "scaleY(1)";
});

/*
 * COOKIES BANNER
 */
const acceptCookies = document.getElementById("accept");
const refuseCookies = document.getElementById("refuse");

if (!getCookie("use_cookies")) {
  document.getElementById("cookies_banner").classList.remove("!hidden");
}

acceptCookies.addEventListener("click", () => {
  setCookie("use_cookies", "true", 182);
  document.getElementById("cookies_banner").classList.add("!hidden");

  // Google Analytics
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag("js", new Date());

  gtag("config", "G-EH19N4VCEN");
});

refuseCookies.addEventListener("click", () => {
  setCookie("use_cookies", "false", 182);
  document.getElementById("cookies_banner").classList.add("!hidden");
});

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
